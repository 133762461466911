import React from 'react';
import { FaUser, FaGithub, FaLinkedinIn } from 'react-icons/fa';
const SocialMedia = () => (
  <div class="app__social">
    <a href="https://flowcv.com/resume/ufhthihp30" target="_blank" rel="noopener noreferrer">
      <div title="Resume">
          <FaUser />
      </div>
    </a>
    <a href="https://github.com/karinepatricia" target="_blank" rel="noopener noreferrer">
      <div title="GitHub">
          <FaGithub />
      </div>
    </a> 
    <a href="https://www.linkedin.com/in/karinepatricia/" target="_blank" rel="noopener noreferrer">
      <div title="Linkedin">
          <FaLinkedinIn />
      </div>
    </a>
  </div>
);

export default SocialMedia;
